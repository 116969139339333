<template>
    <div id="manageinfo" class="detailPage">
        <div class="mainBox">
            <div class="contentBox">
                <div class="selectTimePart flex" v-if="size == 1">
                    <el-select
                        v-model="timePart.id"
                        placeholder="请选择时间"
                        class="searchItem sjtbSelect"
                        clearable
                        @change="timeChange()"
                        filterable
                        size="mini"
                    >
                        <el-option
                            v-for="(item, index) in timePart.list"
                            :key="index"
                            :label="item.DCSJ"
                            :value="item.ID"
                        >
                        </el-option>
                    </el-select>
                    <div class="load" @click="exportDetailItem()">下载</div>
                </div>
                <div class="titleBox">景迈山传统民居遗产监测表</div>
                <div class="headBox">
                    <div class="basicInfo">
                        <div class="infoItem">
                            <div class="name">行政村:</div>
                            <div class="value">{{ detailData.XZC }}</div>
                        </div>
                        <div class="infoItem">
                            <div class="name">村民小组:</div>
                            <div class="value">{{ detailData.XZZ }}</div>
                        </div>
                        <div class="infoItem">
                            <div class="name">民居编号:</div>
                            <div class="value">{{ detailData.MJBH }}</div>
                        </div>
                    </div>
                    <div class="time">
                        <div class="name">监测日期:</div>
                        <div class="value" style="margin-left: 15px">
                            {{ time }}
                        </div>
                    </div>
                </div>
                <div class="tableBox scrollbar">
                    <table border="1" bordercolor="#c5c5c5">
                        <!-- bordercolor="#ccc" cellspacing="0" cellpadding="0" -->
                        <tr>
                            <td rowspan="9" class="name">基本信息</td>
                            <td class="itemLable">
                                <span style="color: red">*</span>户主姓名
                            </td>
                            <td class="itemValue" v-if="size == '1'">
                                {{ detailData.HZXM }}
                            </td>
                            <td class="itemValue" v-if="size == '2'">
                                <el-input v-model="detailData.HZXM"></el-input>
                            </td>
                            <td class="itemLable">身份证号码</td>
                            <td class="itemValue" v-if="size == '1'">
                                {{ detailData.SFZH }}
                            </td>
                            <td class="itemValue" v-if="size == '2'">
                                <el-input
                                    maxlength="18"
                                    :class="{ error: !sfzOk }"
                                    @blur="checkSfzh"
                                    @input.native="onKeyup1('SFZH')"
                                    v-model="detailData.SFZH"
                                >
                                </el-input>
                            </td>
                        </tr>
                        <tr>
                            <td class="itemLable">开户银行</td>
                            <td class="itemValue" v-if="size == '1'">
                                {{ detailData.KHYH }}
                            </td>
                            <td class="itemValue" v-if="size == '2'">
                                <el-input
                                    maxlength="50"
                                    v-model="detailData.KHYH"
                                >
                                </el-input>
                            </td>
                            <td class="itemLable">银行卡号</td>
                            <td class="itemValue" v-if="size == '1'">
                                {{ detailData.YHKH }}
                            </td>
                            <td class="itemValue" v-if="size == '2'">
                                <el-input
                                    :class="{ error: !yhkhOk }"
                                    @blur="checkYhkh"
                                    maxlength="50"
                                    @input.native="onKeyup2('YHKH')"
                                    @keyup.native="onKeyup2('YHKH')"
                                    v-model="detailData.YHKH"
                                >
                                </el-input>
                            </td>
                        </tr>
                        <tr>
                            <td class="itemLable">户籍人口</td>
                            <td class="itemValue" v-if="size == '1'">
                                {{ detailData.JTRS }}
                            </td>
                            <td class="itemValue" v-if="size == '2'">
                                <el-input
                                    maxlength="3"
                                    placeholder="请输入整数"
                                    @input.native="onKeyup2('JTRS')"
                                    @keyup.native="onKeyup2('JTRS')"
                                    v-model="detailData.JTRS"
                                >
                                    <template slot="append">人</template>
                                </el-input>
                            </td>
                            <td class="itemLable">常住人口</td>
                            <td class="itemValue" v-if="size == '1'">
                                {{ detailData.CZRS }}
                            </td>
                            <td class="itemValue" v-if="size == '2'">
                                <el-input
                                    maxlength="3"
                                    placeholder="请输入整数"
                                    @input.native="onKeyup2('CZRS')"
                                    @keyup.native="onKeyup2('CZRS')"
                                    v-model="detailData.CZRS"
                                >
                                    <template slot="append">人</template>
                                </el-input>
                            </td>
                        </tr>
                        <tr>
                            <td class="itemLable">联系电话</td>
                            <td
                                class="itemValue"
                                v-if="size == '1'"
                                style="border-right: none"
                            >
                                {{ detailData.LXDH }}
                            </td>
                            <td
                                class="itemValue"
                                v-if="size == '2'"
                                style="border-right: none"
                            >
                                <!-- :class="{'data_item_table':lxdhOk}" -->
                                <el-input
                                    maxlength="50"
                                    placeholder="请输入联系电话,如151xxxxxxxx"
                                    :class="{ error: !lxdhOk }"
                                    @blur="checkLxdh"
                                    @input.native="onKeyup4('LXDH')"
                                    @keyup.native="onKeyup4('LXDH')"
                                    v-model="detailData.LXDH"
                                >
                                </el-input>
                            </td>
                        </tr>
                        <tr>
                            <td class="itemLable">上次修缮时间</td>
                            <td class="itemValue" v-if="size == '1'">
                                {{ detailData.SCXSSJ }}
                            </td>
                            <td class="itemValue" v-if="size == '2'">
                                <!-- <el-input @input.native="onKeyup2('SCXSSJ')" v-model="detailData.SCXSSJ"></el-input> -->
                                <el-date-picker
                                    v-model="detailData.SCXSSJ"
                                    align="right"
                                    type="date"
                                    placeholder="选择日期"
                                    value-format="yyyy-MM-dd"
                                    format="yyyy-MM-dd"
                                >
                                </el-date-picker>
                            </td>

                            <td class="itemLable">建房时间</td>
                            <td class="itemValue" v-if="size == '1'">
                                {{ detailData.JFSJ }}
                            </td>
                            <td class="itemValue" v-if="size == '2'">
                                <el-date-picker
                                    v-model="detailData.JFSJ"
                                    align="right"
                                    type="year"
                                    placeholder="选择日期"
                                    value-format="yyyy"
                                    format="yyyy"
                                >
                                </el-date-picker>
                            </td>
                        </tr>
                        <tr>
                            <td class="itemLable">层数</td>
                            <td class="itemValue" v-if="size == '1'">
                                {{ detailData.CS }}
                            </td>
                            <td class="itemValue" v-if="size == '2'">
                                <el-input
                                    maxlength="3"
                                    placeholder="请输入整数"
                                    @input.native="onKeyup2('CS')"
                                    @keyup.native="onKeyup2('CS')"
                                    v-model="detailData.CS"
                                >
                                </el-input>
                            </td>
                            <td class="itemLable">屋脊高度</td>
                            <td class="itemValue" v-if="size == '1'">
                                {{
                                    detailData.WJGD ? `${detailData.WJGD}m` : ""
                                }}
                            </td>
                            <td class="itemValue" v-if="size == '2'">
                                <el-input
                                    maxlength="10"
                                    placeholder="请输入整数"
                                    @input.native="onKeyup3('WJGD')"
                                    @keyup.native="onKeyup3('WJGD')"
                                    v-model="detailData.WJGD"
                                >
                                    <template slot="append">m</template>
                                </el-input>
                            </td>
                        </tr>
                        <tr>
                            <td class="itemLable">宅基地面积</td>
                            <td class="itemValue" v-if="size == '1'">
                                {{
                                    detailData.ZJDMJ
                                        ? `${detailData.ZJDMJ}m²`
                                        : ""
                                }}
                            </td>
                            <td class="itemValue" v-if="size == '2'">
                                <el-input
                                    maxlength="5"
                                    @input.native="onKeyup2('ZJDMJ')"
                                    @keyup.native="onKeyup2('ZJDMJ')"
                                    placeholder="请输入整数"
                                    v-model="detailData.ZJDMJ"
                                >
                                    <template
                                        slot="append"
                                        style="
                                            line-height: 32px;
                                            height: 32px;
                                            padding: 0 9px;
                                            text-align: center;
                                            display: flex;
                                            justify-content: center;
                                        "
                                        >m²</template
                                    >
                                </el-input>
                            </td>
                            <td class="itemLable">建筑面积</td>
                            <td class="itemValue" v-if="size == '1'">
                                {{
                                    detailData.JZMJ
                                        ? `${detailData.JZMJ}m²`
                                        : ""
                                }}
                            </td>
                            <td class="itemValue" v-if="size == '2'">
                                <el-input
                                    maxlength="5"
                                    @input.native="onKeyup2('JZMJ')"
                                    @keyup.native="onKeyup2('JZMJ')"
                                    placeholder="请输入整数"
                                    v-model="detailData.JZMJ"
                                >
                                    <template slot="append">m²</template>
                                </el-input>
                            </td>
                        </tr>
                        <tr>
                            <td class="itemLable">门牌号</td>
                            <td class="itemValue" v-if="size == '1'">
                                {{ detailData.MPH }}
                            </td>
                            <td class="itemValue" v-if="size == '2'">
                                <el-input
                                    maxlength="255"
                                    v-model="detailData.MPH"
                                ></el-input>
                            </td>
                            <td class="itemLable">房屋类型</td>
                            <td class="itemValue radioBox">
                                <el-radio-group
                                    :disabled="size == '1'"
                                    v-model="detailData.FWLX"
                                >
                                    <el-radio
                                        v-for="v in buildList"
                                        :key="v.CODE"
                                        :label="v.CODE"
                                        >{{ v.LABEL }}</el-radio
                                    >
                                </el-radio-group>
                            </td>
                        </tr>
                        <tr v-if="dataGetZtActiveNum == 0">
                            <td class="itemLable">使用现状</td>
                            <td
                                class="singleValue radioBox"
                                style="border-right: none"
                            >
                                <el-radio-group
                                    :disabled="size == '1'"
                                    v-model="detailData.SYXZ"
                                >
                                    <el-radio
                                        v-for="v in syxzList"
                                        :key="v.CODE"
                                        :label="v.CODE"
                                        >{{ v.LABEL }}</el-radio
                                    >
                                </el-radio-group>
                            </td>
                        </tr>
                        <tr>
                            <td class="itemLable">位置</td>
                            <td colspan="3" class="singleValue">
                                <div class="locationBox">
                                    <div class="locationItem">
                                        <div class="locationname">经度:</div>
                                        <div
                                            class="locationvalue"
                                            v-if="size == '1'"
                                        >
                                            {{ detailData.JD }}
                                        </div>
                                        <div
                                            class="locationvalue ycenter"
                                            v-if="size == '2'"
                                        >
                                            <el-input
                                                maxlength="50"
                                                @input.native="inputJWD('JD')"
                                                @keyup.native="inputJWD('WD')"
                                                v-model="detailData.JD"
                                            ></el-input>
                                        </div>
                                    </div>
                                    <div class="locationItem">
                                        <div class="locationname">纬度:</div>
                                        <div
                                            class="locationvalue"
                                            v-if="size == '1'"
                                        >
                                            {{ detailData.WD }}
                                        </div>
                                        <div
                                            class="locationvalue ycenter"
                                            v-if="size == '2'"
                                            style="padding-right: 5px"
                                        >
                                            <el-input
                                                maxlength="50"
                                                @input.native="inputJWD('WD')"
                                                @keyup.native="inputJWD('WD')"
                                                v-model="detailData.WD"
                                            ></el-input>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr style="height: 600px">
                            <td class="name largeName">现状监测情况</td>
                            <td colspan="4">
                                <div class="photoBox">
                                    <div class="photoRow">
                                        <div class="photoItem">
                                            <UpFile
                                                name="roomOut"
                                                ref="roomOut"
                                                label="室外图片"
                                                tip="请上传室外图片(可多张)。"
                                                :size="size"
                                                @actFileSuccess="actFileSuccess"
                                            ></UpFile>
                                        </div>
                                        <div class="photoItem">
                                            <UpFile
                                                name="roomIn"
                                                ref="roomIn"
                                                label="室内图片"
                                                tip="请上传室内图片(可多张)。"
                                                :size="size"
                                                @actFileSuccess="actFileSuccess"
                                            ></UpFile>
                                        </div>
                                    </div>
                                    <div class="photoRow">
                                        <div class="photoItem">
                                            <UpFile
                                                name="part1"
                                                ref="part1"
                                                label="局部图片"
                                                tip="请上传局部图片(可多张)。"
                                                :size="size"
                                                @actFileSuccess="actFileSuccess"
                                            ></UpFile>
                                        </div>
                                        <div class="photoItem">
                                            <UpFile
                                                name="part2"
                                                ref="part2"
                                                label="局部图片"
                                                tip="请上传局部图片(可多张)。"
                                                :size="size"
                                                @actFileSuccess="actFileSuccess"
                                            ></UpFile>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr style="height: 200px">
                            <td class="name largeName">监测图纸</td>
                            <td colspan="4">
                                <div class="monitorPhotoBox">
                                    <div
                                        class="monitorPhotoItem"
                                        v-for="(v, key, i) in monitorPhotoList"
                                        :key="i"
                                    >
                                        <el-tag type="success" class="tag">{{
                                            v.label
                                        }}</el-tag>
                                        <div
                                            v-if="v.FJLJ && size == '2'"
                                            @click="delMonitorPhoto(key)"
                                            class="closeBtn"
                                        >
                                            ×
                                        </div>
                                        <el-image
                                            v-if="v.FJLJ"
                                            style="width: 100%; height: 100%"
                                            :src="v.FJLJ"
                                            v-viewer
                                        >
                                        </el-image>
                                        <el-upload
                                            v-if="!v.FJLJ && size == '2'"
                                            :accept="
                                                '.' + uploadFormart.join(',.')
                                            "
                                            class="avatar-uploader"
                                            :action="`${uploadUrl}UpLoad/FileSave?LJ=TZ`"
                                            :before-upload="beforeAvatarUpload"
                                            list-type="picture-card"
                                            :show-file-list="false"
                                            :on-success="
                                                (reg, file) =>
                                                    handleAvatarMonitorSuccess(
                                                        reg,
                                                        file,
                                                        key
                                                    )
                                            "
                                        >
                                            <i
                                                class="
                                                    el-icon-plus
                                                    avatar-uploader-icon
                                                "
                                            ></i>
                                            <div
                                                class="el-upload__tip"
                                                slot="tip"
                                            >
                                                请上传{{ v.label }}。
                                            </div>
                                        </el-upload>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="name">保护现状</td>
                            <td colspan="4" style="padding-left: 20px">
                                <!-- <el-radio-group :disabled="size=='1'" v-model="detailData.BHXZ">
                                    <el-radio style="line-height: 30px" v-for="v in protectList" :key="v.CODE" :label="v.CODE">{{v.LABEL}}</el-radio>
                                </el-radio-group> -->
                                <el-checkbox-group
                                    v-model="saveZt"
                                    @change="changeSaveZt"
                                >
                                    <el-checkbox
                                        :disabled="size === '1' ? true : false"
                                        style="line-height: 30px"
                                        v-for="v in protectList"
                                        :key="v.CODE"
                                        :label="v.CODE"
                                        >{{ v.LABEL }}</el-checkbox
                                    >
                                </el-checkbox-group>
                            </td>
                        </tr>
                        <tr>
                            <td class="name">综合评估</td>
                            <td colspan="4" style="padding-left: 20px">
                                <el-radio-group
                                    :disabled="size == '1'"
                                    v-model="detailData.ZHPG"
                                >
                                    <el-radio
                                        v-for="v in pgList"
                                        :key="v.CODE"
                                        :label="v.CODE"
                                        >{{ v.LABEL }}</el-radio
                                    >
                                </el-radio-group>
                            </td>
                        </tr>
                        <tr>
                            <td class="name largeName">问题描述</td>
                            <td colspan="4" v-if="size == '1'">
                                {{ detailData.WTMS }}
                            </td>
                            <td colspan="4" v-if="size == '2'">
                                <el-input
                                    v-model="detailData.WTMS"
                                    maxlength="2000"
                                    type="textarea"
                                    :autosize="{ minRows: 10, maxRows: 10 }"
                                    show-word-limit
                                >
                                </el-input>
                            </td>
                        </tr>
                        <tr>
                            <td class="name largeName">群众诉求</td>
                            <td colspan="4" v-if="size == '1'">
                                {{ detailData.QZSQ }}
                            </td>
                            <td colspan="4" v-if="size == '2'">
                                <el-input
                                    v-model="detailData.QZSQ"
                                    maxlength="2000"
                                    type="textarea"
                                    :autosize="{ minRows: 10, maxRows: 10 }"
                                    show-word-limit
                                >
                                </el-input>
                            </td>
                        </tr>
                        <tr>
                            <td class="name largeName">处理意见及建议</td>
                            <td colspan="4" v-if="size == '1'">
                                {{ detailData.CLJYJJY }}
                            </td>
                            <td colspan="4" v-if="size == '2'">
                                <el-input
                                    v-model="detailData.CLJYJJY"
                                    maxlength="2000"
                                    type="textarea"
                                    :autosize="{ minRows: 10, maxRows: 10 }"
                                    show-word-limit
                                >
                                </el-input>
                            </td>
                        </tr>
                        <tr>
                            <td class="name largeName">处理结果</td>
                            <td colspan="4" v-if="size == '1'">
                                {{ detailData.CLJG }}
                            </td>
                            <td colspan="4" v-if="size == '2'">
                                <el-input
                                    v-model="detailData.CLJG"
                                    maxlength="2000"
                                    type="textarea"
                                    :autosize="{ minRows: 10, maxRows: 10 }"
                                    show-word-limit
                                >
                                </el-input>
                            </td>
                        </tr>
                        <tr>
                            <td class="name">备注</td>
                            <td colspan="4" v-if="size == '1'">
                                {{ detailData.BZ }}
                            </td>
                            <td colspan="4" v-if="size == '2'">
                                <el-input
                                    v-model="detailData.BZ"
                                    maxlength="200"
                                    type="textarea"
                                    :autosize="{ minRows: 4, maxRows: 4 }"
                                    show-word-limit
                                >
                                </el-input>
                            </td>
                        </tr>
                        <tr class="InfoBox">
                            <td class="name">监测员</td>
                            <td colspan="4">
                                <div class="monitorBox">
                                    <!-- <div class="monitorValue" v-if="size == '1'">{{detailData.DCRXM}}</div>
                                    <div class="monitorValue ycenter" v-if="size == '2'">
                                        <el-input maxlength='50' v-model="detailData.DCRXM"></el-input>
                                    </div> -->
                                    <div class="monitorValue">
                                        {{ detailData.DCRXM }}
                                    </div>
                                    <div class="tel">电话</div>
                                    <div
                                        class="monitorValue"
                                        v-if="size == '1'"
                                    >
                                        {{ detailData.JCYLXDH }}
                                    </div>
                                    <div
                                        class="monitorValue ycenter"
                                        v-if="size == '2'"
                                    >
                                        <el-input
                                            maxlength="50"
                                            @input.native="onKeyup4('JCYLXDH')"
                                            @keyup.native="onKeyup4('JCYLXDH')"
                                            @blur="checkJcyLxdh"
                                            v-model="detailData.JCYLXDH"
                                            :class="{ error: !jcyLxdhOk }"
                                        >
                                        </el-input>
                                    </div>
                                    <div class="people">抽查员</div>
                                    <div class="monitorValue">
                                        {{ detailData.CCRXM }}
                                    </div>
                                    <!-- <div class="monitorValue" v-if="size == '1'">{{detailData.CCRXM}}</div>
                                    <div class="monitorValue ycenter" v-if="size == '2'">
                                        <el-input maxlength='50' v-model="detailData.CCRXM"></el-input>
                                    </div> -->
                                    <div class="tel">电话</div>
                                    <div
                                        class="monitorValue"
                                        v-if="size == '1'"
                                    >
                                        {{ detailData.CCYLXDH }}
                                    </div>
                                    <div
                                        class="monitorValue ycenter"
                                        v-if="size == '2'"
                                    >
                                        <el-input
                                            maxlength="50"
                                            @input.native="onKeyup4('CCYLXDH')"
                                            @keyup.native="onKeyup4('CCYLXDH')"
                                            @blur="checkCcyLxdh"
                                            v-model="detailData.CCYLXDH"
                                            :class="{ error: !ccyLxdhOk }"
                                        >
                                        </el-input>
                                    </div>
                                    <div class="people">交办人</div>
                                    <div
                                        class="monitorValue"
                                        v-if="size == '1'"
                                    >
                                        {{ detailData.JBRMC }}
                                    </div>
                                    <div
                                        class="monitorValue ycenter"
                                        v-if="size == '2'"
                                    >
                                        <el-input
                                            maxlength="50"
                                            v-model="detailData.JBRMC"
                                        ></el-input>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="footerBox">
                    <div
                        class="btn btnBack"
                        v-if="size == '2'"
                        @click="saveData"
                    >
                        保存
                    </div>
                    <div class="btn btnSave" @click="back">返回</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
"use strict";
import * as TYPES from "@store/modules/mutation-type.js";
import { mapActions, mapMutations, mapGetters } from "vuex";
import UpFile from "./comp/UpFile";
export default {
    name: "detail",
    components: {
        UpFile,
    },
    data() {
        return {
            timePart: {
                id: "",
                list: [],
            },
            imgList: [], //查看上传的图片
            size: null, // 1 查看 2 编辑
            strId: null,
            typeId: null, //0 调查 1 抽查
            detailData: {
                XZC: "", // 行政村
                XZZ: "", // 行政组
                MJBH: "", // 民居编号
                DCSJ: "", // 调查时间
                HZXM: "", // 户主姓名
                KHYH: "", // 开户银行
                SFZH: "", // 身份证号
                JTRS: "", // 家庭人数
                YHKH: "", // 银行卡号
                LXDH: "", // 住户联系电话
                CZRS: "", // 常住人口
                JFSJ: "", // 建房时间
                SCXSSJ: "", // 上次修缮时间
                CS: "", // 层数
                WJGD: "", // 屋脊高度(m)
                ZJDMJ: "", // 宅基地面积(m2)
                JZMJ: "", // 建筑面积(m2)
                MPH: "", // 门牌号
                FWLX: "", // 房屋类型
                SYXZ: "", //使用现状
                WTMS: "", // 问题描述
                QZSQ: "", // 群众诉求
                CLJYJJY: "", // 处理意见及建议
                CLJG: "", // 处理结果
                BZ: "", // 备注
                JD: "", // 经度
                WD: "", // 纬度
                BHXZ: "", // 保护现状
                ZHPG: "", // 综合评估
                DCRXM: "", // 监测人姓名
                CCRXM: "", // 抽查人姓名
                JCYLXDH: "", // 监测员联系电话
                CCYLXDH: "", // 抽查员联系电话
                DCSJ: "", // 调查时间
                JBRMC: "", // 交办人
            },
            time: "",
            buildList: [],
            syxzList: [],
            pgList: [],
            protectList: [],
            photoList: {
                roomIn: {
                    fileList: [],
                },
                roomOut: {
                    fileList: [],
                },
                part1: {
                    fileList: [],
                },
                part2: {
                    fileList: [],
                },
            },
            uploadUrl: REQUEST_URL_CTMJ,
            uploadFormart: ["gif", "jpg", "jpeg", "png"],
            sfzOk: true, // 验证身份证
            yhkhOk: true, // 验证银行卡号
            lxdhOk: true, // 验证联系电话
            jcyLxdhOk: true, // 验证监测员联系电话
            ccyLxdhOk: true, // 验证抽查员联系电话
            saveZt: [],
            // 监测图纸栏，包括5张图纸，一般是4张，房屋位置图、一层平面图、二层平面图、剖面图、屋顶俯看图
            monitorPhotoList: {
                roomWzt: {
                    FJLJ: "",
                    label: "房屋位置图",
                    ID: "",
                    LB: "1",
                },
                roomYcpmt: {
                    FJLJ: "",
                    label: "一层平面图",
                    ID: "",
                    LB: "2",
                },
                roomEcpmt: {
                    FJLJ: "",
                    label: "二层平面图",
                    ID: "",
                    LB: "3",
                },
                roomPmt: {
                    FJLJ: "",
                    label: "剖面图",
                    ID: "",
                    LB: "4",
                },
                roomWdfkt: {
                    FJLJ: "",
                    label: "屋顶俯看图",
                    ID: "",
                    LB: "5",
                },
            },
            roleId: localStorage.roleId.split(","),
            dcyId: window.DCY, //调查人id
            ccyId: window.CCY, //抽查人id
        };
    },
    computed: {
        ...mapGetters({
            dataGetZtActiveNum: TYPES.dataGetZtActiveNum, //0 调查 1 抽查
        }),
    },
    mounted() {
        this.size = this.$route.params.size; // 1 查看 2 编辑
        this.strId = this.$route.params.strId ? this.$route.params.strId : "";
        this.typeId = this.$route.params.typeId; //0 调查 1 抽查
        if (this.strId) {
            if (this.$route.params.size == 1 && this.$route.params.mjbh) {
                this.getTimeList();
            } else {
                this.getDetailData(this.strId);
            }
        }
        this.getEnumData();
    },
    methods: {
        ...mapActions([
            "GetJmdcDetail",
            "GetJmdcCcyDetail",
            "GetAllEnumInfobytableID",
            "SaveJmdcData",
            "SaveJmdcCcyData",
            "GetMjDcsjList",
        ]),
        // 获得详情
        async getTimeList() {
            // mjbh: "WW20", 有数据
            let res = await this.GetMjDcsjList({
                mjbh: this.$route.params.mjbh,
            });
            if (res.ISSUCCESS) {
                this.timePart.list = res.RESULTVALUE;
                if (this.timePart.list.length) {
                    this.timePart.id = res.RESULTVALUE[0].ID;
                    this.getDetailData(this.timePart.id);
                } else {
                    this.getDetailData(this.strId);
                }
            }
        },
        timeChange() {
            if (this.timePart.id) {
                this.getDetailData(this.timePart.id);
            } else {
                this.getDetailData(this.strId);
            }
        },
        // 导出
        exportDetailItem() {
            let lx = "";
            let id = "";
            // 0 调查 1 抽查
            if (this.$route.params.typeId == 0) {
                lx = 1;
            } else {
                lx = 2;
            }
            if (this.timePart.id) {
                id = this.timePart.id;
            } else {
                id = this.strId;
            }
            let url = `${window.REQUEST_URL_CTMJ}ExportData/ExportWord?id=${id}&lx=${lx}`;
            window.open(url);
        },
        // 获得详情
        async getDetailData(id) {
            let url = "";
            if (this.typeId === "0") {
                // 调查
                url = "GetJmdcDetail";
            } else {
                // 抽查
                url = "GetJmdcCcyDetail";
            }

            let res = await this[url]({ id: id });
            if (res.ISSUCCESS) {
                this.detailData = res.RESULTVALUE;

                if (this.detailData.FWLX) {
                    this.detailData.FWLX = parseInt(this.detailData.FWLX);
                }
                if (this.detailData.SYXZ) {
                    this.detailData.SYXZ = parseInt(this.detailData.SYXZ);
                }
                if (this.detailData.ZHPG) {
                    this.detailData.ZHPG = String(this.detailData.ZHPG);
                }
                if (this.detailData.DCSJ) {
                    this.time = this.getTimeYmd(this.detailData.DCSJ);
                }

                // 保护现状 字符串转数组
                if (this.detailData.BHXZ) {
                    this.saveZt = [];
                    this.detailData.BHXZ.split(",").forEach((v) => {
                        this.saveZt.push(String(v));
                    });
                }
                if (
                    this.detailData.FILELIST &&
                    this.detailData.FILELIST.length
                ) {
                    let roomInList = [];
                    let roomOutList = [];
                    let part1List = [];
                    let part2List = [];
                    this.detailData.FILELIST.forEach((item) => {
                        let obj = {
                            ID: item.ID,
                            url: item.FJLJ,
                            name: item.FJMC,
                        };
                        switch (item.LB) {
                            case 1:
                                roomOutList.push(obj);
                                break;
                            case 2:
                                roomInList.push(obj);
                                break;
                            case 3:
                                part1List.push(obj);
                                break;
                            case 4:
                                part2List.push(obj);
                                break;
                        }
                    });

                    this.photoList.roomIn.fileList = roomInList;
                    this.photoList.roomOut.fileList = roomOutList;
                    this.photoList.part1.fileList = part1List;
                    this.photoList.part2.fileList = part2List;
                    this.$refs.roomOut.fill(roomOutList);
                    this.$refs.roomIn.fill(roomInList);
                    this.$refs.part1.fill(part1List);
                    this.$refs.part2.fill(part2List);
                }

                if (this.detailData.TZLIST && this.detailData.TZLIST.length) {
                    this.detailData.TZLIST.forEach((v) => {
                        // 1 房屋位置图
                        if (v.LB == "1") {
                            $.extend(this.monitorPhotoList.roomWzt, v);
                        }
                        // 2 一层平面图
                        if (v.LB == "2") {
                            $.extend(this.monitorPhotoList.roomYcpmt, v);
                        }
                        // 3 二层平面图
                        if (v.LB == "3") {
                            $.extend(this.monitorPhotoList.roomEcpmt, v);
                        }
                        // 4 剖面图
                        if (v.LB == "4") {
                            $.extend(this.monitorPhotoList.roomPmt, v);
                        }
                        // 5 屋顶俯看图
                        if (v.LB == "5") {
                            $.extend(this.monitorPhotoList.roomWdfkt, v);
                        }
                    });
                }

                this.checkSfzh();
                this.checkYhkh();
                this.checkLxdh();
                this.checkJcyLxdh();
                this.checkCcyLxdh();
            }
        },
        checkSfzh() {
            let strzz =
                /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
            this.sfzOk = strzz.test(this.detailData.SFZH);
        },
        checkYhkh() {
            let strzz = /^([1-9]{1})(\d{14}|\d{18})$/;
            this.yhkhOk = strzz.test(this.detailData.YHKH);
        },
        checkLxdh() {
            let strzz =
                /^(0[0-9]{2,3}\-)?([2-9][0-9]{6,7})+(\-[0-9]{1,4})?$|(^(1[0-9][0-9]|15[0|3|6|7|8|9]|18[8|9])\d{8}$)/;
            this.lxdhOk = strzz.test(this.detailData.LXDH);
        },
        checkJcyLxdh() {
            let strzz =
                /^(0[0-9]{2,3}\-)?([2-9][0-9]{6,7})+(\-[0-9]{1,4})?$|(^(13[0-9]|15[0|3|6|7|8|9]|18[8|9])\d{8}$)/;
            this.jcyLxdhOk = strzz.test(this.detailData.JCYLXDH);
        },
        checkCcyLxdh() {
            let strzz =
                /^(0[0-9]{2,3}\-)?([2-9][0-9]{6,7})+(\-[0-9]{1,4})?$|(^(13[0-9]|15[0|3|6|7|8|9]|18[8|9])\d{8}$)/;
            this.ccyLxdhOk = strzz.test(this.detailData.CCYLXDH);
        },
        // 获取时间 年月日
        getTimeYmd(value) {
            let date = new Date(value);
            let data_year = date.getFullYear();
            let data_month;
            let data_day;

            if (date.getMonth() + 1 < 10) {
                data_month = "0" + (date.getMonth() + 1);
            } else {
                data_month = date.getMonth() + 1;
            }

            if (date.getDate() + 1 <= 10) {
                data_day = "0" + date.getDate();
            } else {
                data_day = date.getDate();
            }
            return data_year + "年" + data_month + "月" + data_day + "日";
        },
        // 返回
        back() {
            this.$router.push({
                name: "data_get",
            });
        },
        // 获取枚举值
        async getEnumData() {
            let result = await this.GetAllEnumInfobytableID({
                bid: "jmdcqkb",
            });
            result.RESULTVALUE.map((item) => {
                if (item.COLUMNID == "BHXZ") {
                    this.protectList = item.DATAITEMS;
                }
                if (item.COLUMNID == "FWLX") {
                    item.DATAITEMS.map((item) => {
                        item.CODE = Number(item.CODE);
                    });
                    this.buildList = item.DATAITEMS;
                }
                if (item.COLUMNID == "ZHPG") {
                    this.pgList = item.DATAITEMS;
                }
                if (item.COLUMNID == "SYXZ") {
                    item.DATAITEMS.map((item) => {
                        item.CODE = Number(item.CODE);
                    });
                    this.syxzList = item.DATAITEMS;
                }
            });
        },
        getTpStr() {
            let listAry = [];
            let roomInList = [];
            let roomOutList = [];
            let part1List = [];
            let part2List = [];
            this.photoList.roomOut.fileList.map((item, index) => {
                let obj = {
                    ID: item.ID,
                    LB: 1,
                    PX: index,
                };
                roomOutList.push(obj);
            });
            this.photoList.roomIn.fileList.map((item, index) => {
                let obj = {
                    ID: item.ID,
                    LB: 2,
                    PX: index,
                };
                roomInList.push(obj);
            });

            this.photoList.part1.fileList.map((item, index) => {
                let obj = {
                    ID: item.ID,
                    LB: 3,
                    PX: index,
                };
                part1List.push(obj);
            });

            this.photoList.part2.fileList.map((item, index) => {
                let obj = {
                    ID: item.ID,
                    LB: 4,
                    PX: index,
                };
                part2List.push(obj);
            });

            listAry = [
                ...roomInList,
                ...roomOutList,
                ...part1List,
                ...part2List,
            ];
            return listAry;
        },
        // 保存
        async saveData() {
            if (!this.detailData.HZXM) {
                this.common.showMsg("请输入户主姓名", "error");
                return false;
            }
            this.checkSfzh();
            this.checkYhkh();
            this.checkLxdh();
            this.checkJcyLxdh();
            this.checkCcyLxdh();
            if (this.detailData.SJZT == "2" && this.typeId === "1") {
                this.detailData.SJZT = 3;
            }
            let tzStr = [];
            for (let key in this.monitorPhotoList) {
                if (this.monitorPhotoList[key].ID) {
                    tzStr.push({
                        ID: this.monitorPhotoList[key].ID,
                        LB: this.monitorPhotoList[key].LB,
                    });
                }
            }
            if (this.detailData.TZLIST) {
                delete this.detailData.TZLIST;
            }
            if (this.detailData.FILELIST) {
                delete this.detailData.FILELIST;
            }
            if (this.detailData.SYXZ) {
                this.detailData.SYXZ = parseInt(this.detailData.SYXZ);
            }

            let tpStr = this.getTpStr();
            let url = "";
            if (this.typeId === "0") {
                url = "SaveJmdcData";
            } else {
                url = "SaveJmdcCcyData";
            }
            let res = await this[url]({
                jsonStr: JSON.stringify(this.detailData),
                tzStr: tzStr,
                tpStr: tpStr,
                cjfs: "0",
            });
            if (res.ISSUCCESS) {
                this.common.showMsg("保存成功", "success");
                this.photoList.roomIn.fileList = [];
                this.photoList.roomOut.fileList = [];
                this.photoList.part1.fileList = [];
                this.photoList.part2.fileList = [];
                this.back();
            }
        },
        beforeAvatarUpload(file) {
            let size =
                file.type && file.type.split("/") && file.type.split("/")[1];
            if (!this.uploadFormart.includes(size)) {
                let msg = this.uploadFormart.join(",");
                this.$message.error(`上传图片只能是 ${msg} 格式!`);
                return false;
            } else {
                return true;
            }
        },
        delMonitorPhoto(key) {
            this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.monitorPhotoList[key].FJLJ = "";
                    this.monitorPhotoList[key].ID = "";
                })
                .catch(() => {
                    this.common.showMsg("已取消删除", "info");
                });
        },
        handleAvatarMonitorSuccess(res, file, key) {
            if (res.IsSuccess) {
                let data = res.ResultValue && res.ResultValue[0];
                this.monitorPhotoList[key].FJLJ = data.FilePath;
                this.monitorPhotoList[key].ID = data.Guid;
            }
        },
        onKeyup1(v) {
            this.detailData[v] = ("" + this.detailData[v]).replace(
                /[^0-9xX]+/g,
                ""
            );
        },
        onKeyup2(v) {
            this.detailData[v] = ("" + this.detailData[v]).replace(
                /[^0-9]+/g,
                ""
            );
        },
        onKeyup3(v) {
            this.detailData[v] = ("" + this.detailData[v]).replace(
                /[^0-9.]+/g,
                ""
            );
        },
        onKeyup4(v) {
            this.detailData[v] = ("" + this.detailData[v]).replace(
                /[^0-9-]+/g,
                ""
            );
        },
        inputJWD(v) {
            // var match = '';
            this.detailData[v] = ("" + this.detailData[v]).replace(
                /[^0-9.]+/g,
                ""
            );
            // let match = ((''+this.detailData[v]).match(/\d+(\.\d{0,8})?/) || [''])[0];
            // this.detailData[v] = match.substr(0,2);
            // if(name == 'lat_d'){
            //     match = (item[name].match(/\d+/) || [''])[0];
            // }else{
            //     match = (item[name].match(/\d+(\.\d{0,8})?/) || [''])[0];
            // }
            // if(match > 90){
            //     if(match.length >= 3){
            //     item[name] = match.substr(0,2);
            //     }else{
            //     item[name] = match.substr(0,1);
            //     }
            // }else{
            //     item[name] = match;
            // }
            // if(name == 'lat_d' && item[name] == 90){
            //     item.lat_m = 0;
            //     item.lat_s = 0;
            // }
        },
        // 修改保存现状
        changeSaveZt() {
            this.detailData.BHXZ = this.saveZt.join(",");
        },
        actFileSuccess(list, key) {
            this.photoList[key].fileList = list;
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
    },
};
</script>
<style>
.photoItem img {
    max-width: 400px;
    max-height: 297px;
    width: unset;
    height: unset;
}
.monitorPhotoItem img {
    max-width: 160px;
    max-height: 197px;
    width: unset;
    height: unset;
}
.el-image {
    display: flex;
    align-items: center;
    justify-content: center;
}
.WJGD .el-input-group__append {
    line-height: 32px;
    height: 32px;
    padding: 0 9px;
    text-align: center;
    display: flex;
    justify-content: center;
}
.WJGD .el-input__inner {
    padding: 0 5px;
}
.error .el-input__inner {
    border: 1px red solid;
}
.InfoBox .el-input__inner {
    padding: 0 5px;
}
</style>
<style scoped lang="scss">
#manageinfo {
    position: absolute;
    top: 78px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    .mainBox {
        width: 100%;
        height: 100%;
        background: #f0f0f0;
        .contentBox {
            height: 100%;
            width: 1080px;
            margin: 0 auto;
            background: #ffffff;
            position: relative;
            .selectTimePart {
                position: absolute;
                left: 20px;
                top: 20px;
                .load {
                    margin-left: 10px;
                    cursor: pointer;
                    width: 80px;
                    height: 30px;
                    line-height: 30px;
                    color: #ffffff;
                    text-align: center;
                    border-radius: 5px;
                    margin-left: 10px;
                    background: #dc960f;
                }
            }
            .titleBox {
                line-height: 80px;
                height: 80px;
                text-align: center;
                font-size: 24px;
            }
            .headBox {
                display: flex;
                justify-content: space-between;
                margin: 0 20px;
                height: 40px;
                line-height: 40px;
                .basicInfo {
                    display: flex;
                    justify-content: flex-start;
                }
                .time {
                    width: 240px;
                    display: flex;
                }
                .infoItem {
                    width: 180px;
                    display: flex;
                    .name {
                    }
                    .value {
                        margin: 0 5px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        max-width: 114px;
                    }
                }
            }
            .tableBox {
                width: calc(100% - 40px);
                margin: 0 20px;
                height: calc(100% - 140px);
                overflow-y: auto;
                overflow-x: hidden;
                position: relative;
                box-sizing: border-box;
                table {
                    border-collapse: collapse;
                    border-color: #c5c5c5;
                    width: 100%;
                    height: 100%;
                    .name {
                        background: #f4fafa;
                        width: 200px;
                        text-align: center;
                        line-height: 40px;
                    }
                    .itemLable {
                        width: 150px;
                        text-align: center;
                        line-height: 40px;
                    }
                    .itemValue {
                        width: 200px;
                        padding: 0 5px;
                    }
                    .singleValue {
                        width: 260px;
                        padding: 0 5px;
                    }
                    .largeName {
                        height: 200px;
                    }
                    .fangwu {
                        display: flex;
                        justify-content: space-between;
                        .lable {
                            width: 80px;
                            border-left: 1px solid #c5c5c5;
                            height: 40px;
                            border-right: 1px solid #c5c5c5;
                            line-height: 40px;
                            text-align: center;
                        }
                        .value {
                            flex: 1;
                            height: 40px;
                            line-height: 40px;
                        }
                    }
                    .locationBox {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        line-height: 40px;
                        height: 40px;
                        .locationItem {
                            flex: 1;
                            display: flex;
                            justify-content: space-between;
                            .locationname {
                                width: 80px;
                                text-align: center;
                            }
                            .locationvalue {
                                flex: 1;
                            }
                        }
                    }
                    .monitorBox {
                        display: flex;
                        justify-content: space-between;
                        line-height: 40px;
                        height: 40px;
                        text-align: center;
                        .tel {
                            width: 60px;
                            border-left: 1px solid #c5c5c5;
                            border-right: 1px solid #c5c5c5;
                        }
                        .people {
                            background: #f4fafa;
                            width: 60px;
                            border-left: 1px solid #c5c5c5;
                            border-right: 1px solid #c5c5c5;
                        }
                        .monitorValue {
                            flex: 1;
                            padding: 0 5px;
                        }
                    }
                    .photoBox {
                        height: 100%;
                        .photoRow {
                            height: 50%;
                            display: flex;
                            justify-content: space-between;
                            &:nth-child(1) {
                                border-bottom: 1px solid #c5c5c5;
                            }
                            .photoItem {
                                flex: 1;
                                height: 100%;
                                position: relative;
                                padding: 2px;
                                &:nth-child(1) {
                                    border-right: 1px solid #c5c5c5;
                                }
                            }
                        }
                    }
                    .monitorPhotoBox {
                        display: flex;
                        width: 100%;
                        height: 100%;
                        justify-content: space-between;
                        .monitorPhotoItem {
                            width: 20%;
                            border-right: 1px dashed #ccc;
                            align-items: center;
                            justify-content: center;
                            display: flex;
                            height: 100%;
                            position: relative;
                            .closeBtn {
                                position: absolute;
                                top: 10px;
                                right: 10px;
                                font-size: 18px;
                                cursor: pointer;
                                z-index: 10;
                            }
                            .tag {
                                position: absolute;
                                left: 2px;
                                top: 2px;
                                z-index: 10;
                            }
                        }
                    }
                    .ycenter {
                        display: flex;
                        align-items: center;
                        line-height: 1;
                    }
                }
            }
            .footerBox {
                height: 40px;
                line-height: 40px;
                display: flex;
                justify-content: flex-end;
                position: absolute;
                right: 20px;
                top: 20px;
                .btn {
                    width: 80px;
                    height: 30px;
                    line-height: 30px;
                    color: #ffffff;
                    text-align: center;
                    cursor: pointer;
                    border-radius: 5px;
                }
                .btnBack {
                    background: #009847;
                }
                .btnSave {
                    margin-left: 10px;
                    background: #dc960f;
                }
            }
        }
    }
}
</style>
