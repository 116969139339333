<template>
    <div>
        <el-tag type="success" class="tag">{{ label }}</el-tag>
        <el-upload
            :accept="'.' + uploadFormart.join(',.')"
            class="uploader scrollbar"
            :class="{
                disableShow: size == '1',
            }"
            :style="{ height: height }"
            ref="upload"
            :action="`${uploadUrl}UpLoad/FileSave?LJ=JMDC`"
            :before-upload="beforeUpload"
            :on-preview="previewFile"
            :on-remove="handleRemove"
            list-type="picture-card"
            multiple
            :file-list="fileListTemp"
            :on-success="handleSuccess"
        >
            <i slot="default" class="el-icon-plus"></i>
            <div slot="tip" class="el-upload__tip" v-if="size != '1'">
                {{ tip }}
            </div>
        </el-upload>

        <ViewerAlert :imgList="imgList" ref="myImgList"></ViewerAlert>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapActions, mapMutations, mapGetters } from "vuex";
import ViewerAlert from "@comp/ViewerAlert.vue"; //大图查看

export default {
    name: "",
    components: {
        ViewerAlert,
    },
    data() {
        return {
            uploadUrl: REQUEST_URL_CTMJ,
            uploadFormart: ["gif", "jpg", "jpeg", "png"],
            imgList: [],
            fileListTemp: [],
            fileList: [],
        };
    },
    props: {
        height: {
            type: String,
            default: "260px",
        },
        name: {
            type: String,
            default: "",
        },
        label: {
            type: String,
            default: "",
        },
        tip: {
            type: String,
            default: "",
        },
        size: {
            type: String,
            default: "",
        },
    },
    computed: {
        ...mapGetters({}),
    },
    watch: {},
    mounted() {},
    methods: {
        ...mapMutations([]),
        ...mapActions([]),
        beforeUpload(file) {
            let size =
                file.type && file.type.split("/") && file.type.split("/")[1];
            if (!this.uploadFormart.includes(size)) {
                let msg = this.uploadFormart.join(",");
                this.$message.error(`上传图片只能是 ${msg} 格式!`);
                return false;
            } else {
                return true;
            }
        },
        //查看上传附件
        previewFile() {
            let ary = [];
            this.fileList.map((item, index) => {
                let obj = {
                    alt: item.name,
                    src: item.url,
                    thumbnail: item.url,
                };
                ary.push(obj);
            });
            this.imgList = Array.from(ary);
            if (this.imgList.length) {
                this.$refs.myImgList.showViewer();
            }
        },
        handleSuccess(res, file) {
            if (res.IsSuccess) {
                let data = res.ResultValue && res.ResultValue[0];
                data.url = data.FilePath;
                data.name = data.FileName;
                data.ID = data.Guid;
                this.fileList.push(data);
                this.$emit("actFileSuccess", this.fileList, this.name);
                this.$refs.upload.scrollTop = this.$refs.upload.scrollHeight;
                // console.log("this.fileList", this.fileList);
            }
        },
        //删除图片
        handleRemove(file, fileList) {
            this.fileList = fileList;
            this.$emit("actFileSuccess", this.fileList, this.name);
        },
        // 修改图片
        fill(list) {
            // console.log("修改图片", list);
            this.fileList = Array.from(list);
            this.fileListTemp = Array.from(list);
        },
    },
};
</script>
<style scoped lang="scss">
.tag {
    margin-bottom: 10px;
}
</style>
